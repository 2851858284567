import { CATEGORIES_SELECT } from 'data';
import React from 'react';
import styled from 'styled-components';
import Select, { components } from 'react-select';
import DropdownSVG from 'resources/img/dropdown-icon.svg';

const DropdownIndicator = props => {
    return (
      <components.DropdownIndicator {...props}>
        <img src={DropdownSVG} alt='arrow'/>
      </components.DropdownIndicator>
    );
  };

const CustomSelect = styled(Select)`
    display: none;
    z-index: 2;
    @media screen and (max-width: 768px) {
        display: block;
    }

    .select__control {
        background-color: #FFFFFFCC;
        border-radius: 10px;
    }

    .select__input-container {
        color: #671700!important;
    }

    .select__control--is-focused {
        outline: none;
        border: none;
    }
    .select__control--menu-is-open {
        outline: none;
        border: none;
    }
    .select__menu {
        background-color: #FFFFFFCC;
    }
    .select__option--is-selected {
        color: #671700;
        background-color: #FFFFFFFF;
    }
    .select__option--is-focused {
        background-color: #FFFFFFEE;
    }
`;

const Container = styled.div`
    display: flex;
    column-gap: 40px;
    row-gap: 10px;
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @media screen and (max-width: 768px) {
        display: none;
    }
`;

const Item = styled.div`
    background-color: ${({ selected }) => (selected ? 'white' : 'transparent')};
    opacity: 0.8;
    padding: 5px 10px;
    border-radius: 50px;
    span {
        font-weight: 700;
        margin: 0;
        color: ${({ selected }) => (selected ? '#671700' : 'white')};
    }
    cursor: pointer;
`;

const CategoriesSelect = ({ selectedCategory, onChange }) => {
    return (
        <>
            <CustomSelect
                className="basic-single"
                classNamePrefix="select"
                value={selectedCategory}
                options={CATEGORIES_SELECT}
                onChange={onChange}
                components={{ DropdownIndicator }}
            />
            <Container>
                {CATEGORIES_SELECT.map((category) => (
                    <Item
                        selected={selectedCategory.value === category.value}
                        onClick={() => onChange(category)}
                    >
                        <span>{category.label}</span>
                    </Item>
                ))}
            </Container>
        </>
    );
};

export default CategoriesSelect;
