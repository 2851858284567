import React from 'react';
import styled from 'styled-components';
import BackgroundJPG from 'resources/img/background-squares.jpg';
import { GOLDEN } from 'resources/constants/colors';
import MAIN_DATA from 'data';
import Season from 'components/Season';
import TwitchSVG from 'resources/img/social-icons_twitch.svg';
import YoutubeSVG from 'resources/img/social-icons_youtube.svg';
import InstagramSVG from 'resources/img/social-icons_instagram.svg';
import TwitterSVG from 'resources/img/social-icons_twitter.svg';

const Background = styled.div`
    position: absolute;
    background: url(${BackgroundJPG}) repeat-y;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0.5;
`;

const Main = styled.main`
    padding: 0 20px 50px 20px;
    display: flex;
    flex-direction: column;
    gap: 50px;
`;

const Footer = styled.footer`
    background-color: black;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 20px;
    }
`;

const Socials = styled.div`
    display: flex;
    gap: 20px;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 15px;
    }
`;

const SocialItem = styled.a`
    text-decoration: none;
    color: white;
    div {
        display: flex;
        gap: 5px;
        img {
            width: 20px;
        }
        p {
            color: white;
            text-decoration: none;
            margin: 0;
        }
    }
`;

const PageTitle = styled.h1`
    font-family: 'Zen Tokyo Zoo', cursive;
    color: ${GOLDEN};
    text-align: center;
    font-size: 42px;
    @media screen and (max-width: 768px) {
        font-size: 25px;
    }
`;

const PageDescription = styled.p`
    color: white;
    text-align: center;
    font-size: 25px;
    font-weight: 600;
    @media screen and (max-width: 768px) {
        font-size: 14px;
        margin-bottom: 40px;
    }
`;

const App = () => {
    return (
        <>
            <Background />
            <header>
                <PageTitle>PROFE AWARDS 2022</PageTitle>
                <PageDescription>
                    RESULTADOS DE LAS VOTACIONES DE ANIME 2022 ELEGIDOS POR “LA
                    CLASE”
                </PageDescription>
            </header>
            <Main>
                {MAIN_DATA.map((data) => (
                    <Season data={data} />
                ))}
            </Main>
            <Footer>
                <Socials>
                    <SocialItem
                        href="https://www.twitch.tv/ricarlos97oficial"
                        target={'_blank'}
                    >
                        <div>
                            <img src={TwitchSVG} alt="" />
                            <p>ricarlos97oficial</p>
                        </div>
                    </SocialItem>
                    <SocialItem
                        href="https://twitter.com/Ricarlos97"
                        target={'_blank'}
                    >
                        <div>
                            <img src={TwitterSVG} alt="" />
                            <p>Ricarlos97</p>
                        </div>
                    </SocialItem>
                    <SocialItem
                        href="https://www.youtube.com/@Ricarlos97"
                        target={'_blank'}
                    >
                        <div>
                            <img src={YoutubeSVG} alt="" />
                            <p>Ricarlos97</p>
                        </div>
                    </SocialItem>
                    <SocialItem
                        href="https://www.instagram.com/ricarlos97/"
                        target={'_blank'}
                    >
                        <div>
                            <img src={InstagramSVG} alt="" />
                            <p>Ricarlos97</p>
                        </div>
                    </SocialItem>
                </Socials>
                <p style={{ color: 'white' }}>Website by: Cedric Borreguín</p>
            </Footer>
        </>
    );
};

export default App;
