export const CATEGORIES = {
    SEIYUU_F: 'Mejor Seiyū (F)',
    SEIYUU_M: 'Mejor Seiyū (M)',
    FIRST_IMPRESSION: 'Mejor Primera Impresión',
    SCORE: 'Mejor Banda Sonora',
    ANIMATION: 'Mejor Animación',
    CLOSE: 'Mejor Cierre',
    VILLAIN: 'Mejor Villano',
    HUSBANDO: 'Mejor Husbando',
    WAIFU: 'Mejor Waifu',
    PROTAGONIST: 'Mejor Protagonista',
    ENDING: 'Mejor Ending',
    OPENING: 'Mejor Opening',
    ANIME: 'Mejor Anime',
};

export const STUDIOS = {
    A1: 'A-1 Pictures',
    UFOTABLE: 'Ufotable',
    MAPPA: 'MAPPA',
    WIT: 'Wit Studio',
    CLOVERWORKS: 'CloverWorks',
    WIT_CLOVERWORKS: 'Wit Studio | CloverWorks',
    CYGAMES: 'CygamesPictures',
    OKURUTO: 'Okuruto Noboru',
    FELIX: 'Felix Film',
    KINEMA_DR: 'Kinema Citrus | DR Movie',
    LERCHE: 'Lerche',
    TRIGGER: 'Trigger',
    LIDENFILMS: 'LIDENFILMS',
    JC_STAFF: 'J.C. Staff',
    PASSIONE: 'Passione',
    BONES: 'Bones',
    PA_WORKS: 'P.A. Works',
    NEXUS: 'Nexus',
    PIERROT: 'Pierrot',
};

export const CATEGORIES_SELECT = [
    { value: 'SEIYUU_F', label: CATEGORIES.SEIYUU_F },
    { value: 'SEIYUU_M', label: CATEGORIES.SEIYUU_M },
    { value: 'FIRST_IMPRESSION', label: CATEGORIES.FIRST_IMPRESSION },
    { value: 'SCORE', label: CATEGORIES.SCORE },
    { value: 'ANIMATION', label: CATEGORIES.ANIMATION },
    { value: 'CLOSE', label: CATEGORIES.CLOSE },
    { value: 'VILLAIN', label: CATEGORIES.VILLAIN },
    { value: 'HUSBANDO', label: CATEGORIES.HUSBANDO },
    { value: 'WAIFU', label: CATEGORIES.WAIFU },
    { value: 'PROTAGONIST', label: CATEGORIES.PROTAGONIST },
    { value: 'ENDING', label: CATEGORIES.ENDING },
    { value: 'OPENING', label: CATEGORIES.OPENING },
    { value: 'ANIME', label: CATEGORIES.ANIME },
];

export const NOMINEE_TYPE = {
    PERSON: 'person',
    SONG: 'song',
    ANIME: 'anime',
    CHARACTER: 'character',
};

const MAIN_DATA = [
    {
        title: 'INVIERNO 2022',
        key: 'winter',
        categories: {
            SEIYUU_F: {
                title: CATEGORIES.SEIYUU_F,
                nominees: [
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Komatsu, Mikako',
                        character: 'Maki Zenin',
                        anime: 'Jujutsu Kaisen 0',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Fkomatsu_mikako-min.jpg?alt=media&token=a5fcb5b7-62f2-4aa8-a57a-29e1698be3ca',
                        duration: '1hr 44min',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Suguta, Hina',
                        character: 'Marin Kitagawa',
                        anime: 'Sono Bisque Doll wa Koi wo Suru',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Fsuguta_hina-min.jpg?alt=media&token=ba4244fc-4d08-4c66-a19a-a8ee7ba704b9',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Takahashi, Rie',
                        character: 'Ninym Ralei',
                        anime: 'Tensai Ouji no Akaji Kokka Saisei Jutsu',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Ftakahashi_rie-min.jpg?alt=media&token=05e0c30d-c290-4c00-afc5-bfa75b55b3bf',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            SEIYUU_M: {
                title: CATEGORIES.SEIYUU_M,
                nominees: [
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Konishi, Katsuyuki',
                        character: 'Tengen Uzui',
                        anime: 'Kimetsu no Yaiba: Yuukaku-hen',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Fkonishi_katsuyuki-min.jpg?alt=media&token=44370bb0-9fad-4787-bc36-fdbe5ae58faa',
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Hanae, Natsuki',
                        character: 'Vanitas',
                        anime: 'Vanitas no Karte Part 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Fhanae_natsuki-min.jpg?alt=media&token=3713abd6-9306-44a2-bc9e-610a3b5e13ee',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Oosaka, Ryouta',
                        character: 'Gyuutarou',
                        anime: 'Kimetsu no Yaiba: Yuukaku-hen',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Foosaka_ryouta-min.jpg?alt=media&token=9704f426-883b-4d5b-82ba-14371aebc8cf',
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            FIRST_IMPRESSION: {
                title: CATEGORIES.FIRST_IMPRESSION,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Ousama Ranking',
                        titleAlt: '(Ranking of Kings)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fousama_ranking-min.jpg?alt=media&token=00b3ac59-adca-406a-99cc-ba64f2659394',
                        studio: STUDIOS.WIT,
                        chapterCount: '23 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kimetsu no Yaiba: Yuukaku-hen',
                        titleAlt: '(Demons Slayer: Distrito Rojo)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fkimetsu-min.jpg?alt=media&token=07404bf9-be7d-4cb1-9db2-1c9547c8344c',
                        studio: STUDIOS.UFOTABLE,
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Akebi-chan no Sailor-fuku',
                        titleAlt: "(Akebi's Sailor Uniform)",
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fakebi-min.jpg?alt=media&token=19266827-e118-4ff6-8357-3a182347aa38',
                        studio: STUDIOS.CLOVERWORKS,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            SCORE: {
                title: CATEGORIES.SCORE,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: '86 Part 2',
                        titleAlt: '(86 Eighty-Six Part 2)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2F86-min.jpg?alt=media&token=ad5bba42-4126-45e1-9615-e85250f0921e',
                        studio: STUDIOS.A1,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kimetsu no Yaiba: Yuukaku-hen',
                        titleAlt: '(Demons Slayer: Distrito Rojo)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fkimetsu-min.jpg?alt=media&token=07404bf9-be7d-4cb1-9db2-1c9547c8344c',
                        studio: STUDIOS.UFOTABLE,
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Shingeki no Kyojin: The Final Season Pt 2',
                        titleAlt: '(Attack on Titan: The Final Season Part 2)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fshingeki-min.jpg?alt=media&token=3c9b680f-eea9-454a-846d-68d016b3a467',
                        studio: STUDIOS.MAPPA,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ANIMATION: {
                title: CATEGORIES.ANIMATION,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: '86 Part 2',
                        titleAlt: '(86 Eighty-Six Part 2)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2F86-min.jpg?alt=media&token=ad5bba42-4126-45e1-9615-e85250f0921e',
                        studio: STUDIOS.A1,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kimetsu no Yaiba: Yuukaku-hen',
                        titleAlt: '(Demons Slayer: Distrito Rojo)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fkimetsu-min.jpg?alt=media&token=07404bf9-be7d-4cb1-9db2-1c9547c8344c',
                        studio: STUDIOS.UFOTABLE,
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Shingeki no Kyojin: The Final Season Pt 2',
                        titleAlt: '(Attack on Titan: The Final Season Part 2)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fshingeki-min.jpg?alt=media&token=3c9b680f-eea9-454a-846d-68d016b3a467',
                        studio: STUDIOS.MAPPA,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            CLOSE: {
                title: CATEGORIES.CLOSE,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Princess Connect! Re:Dive',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fprincess_connect-min.jpg?alt=media&token=cf4634b5-8bca-43ea-a17b-9d14afbc3c6b',
                        studio: STUDIOS.CYGAMES,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: '86 Part 2',
                        titleAlt: '(86 Eighty-Six Part 2)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2F86-min.jpg?alt=media&token=ad5bba42-4126-45e1-9615-e85250f0921e',
                        studio: STUDIOS.A1,
                        chapterCount: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Sono Bisque Doll wa Koi wo Suru',
                        titleAlt: '(My Dress-Up Darling)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fsono_bisque-min.jpg?alt=media&token=c445183d-3ce7-4d5a-8c13-3b82d4824ca6',
                        studio: STUDIOS.CLOVERWORKS,
                        duration: 12,
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            VILLAIN: {
                title: CATEGORIES.VILLAIN,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Eren Jeager',
                        anime: 'Shingeki no Kyojin: The Final Season Pt 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Feren_jeager-min.jpg?alt=media&token=b52d777a-a3b6-40bf-85a9-62cf64c8cb24',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Daki & Gyuutarou',
                        anime: 'Kimetsu no Yaiba: Yuukaku-hen',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fdaky_gyuutarou-min.jpg?alt=media&token=ddfb9669-126f-4906-bfb2-6ad1a2af254f',
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Charlatan',
                        anime: 'Vanitas no Karte Part 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fcharlatan-min.jpg?alt=media&token=559836d4-8a54-4436-a6c1-3c1fd447d9ba',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            HUSBANDO: {
                title: CATEGORIES.HUSBANDO,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Hajime Nagumo',
                        anime: 'Arifureta Shokugyou de Sekai Saikyou 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fhajime_nagumo-min.jpg?alt=media&token=5a0a391d-7bcc-413b-b908-27cb0d907b2e',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Vanitas',
                        anime: 'Vanitas no Karte Part 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fvanitas-min.jpg?alt=media&token=379cd6bf-a1a9-4b93-a5d3-bb9753fceedf',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Tengen Uzui',
                        anime: 'Kimetsu no Yaiba: Yuukaku-hen',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Ftengen_uzui-min.jpg?alt=media&token=f8445063-e642-4c9d-ac5c-755cf6cd9917',
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            WAIFU: {
                title: CATEGORIES.WAIFU,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Ninym Ralei',
                        anime: 'Tensai Ouji no Akaji Kokka Saisei Jutsu',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fninym_ralei-min.jpg?alt=media&token=72fe1b43-11d3-4632-937c-e44f2b944bed',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Marin Kitagawa',
                        anime: 'Sono Bisque Doll wa Koi wo Suru',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fmarin_kitagawa-min.jpg?alt=media&token=bdccc3a9-5be4-4dec-87a7-0f51dfd74562',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Vladilena Milizé',
                        anime: '86 Eighty-Six Part 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fvladilena_milize-min.jpg?alt=media&token=fd1bc463-8051-48c6-b535-f3f6dffd51d0',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            PROTAGONIST: {
                title: CATEGORIES.PROTAGONIST,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Eren Jeager',
                        anime: 'Shingeki no Kyojin: The Final Season Pt 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Feren_jeager-min.jpg?alt=media&token=b52d777a-a3b6-40bf-85a9-62cf64c8cb24',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Hajime Nagumo',
                        anime: 'Arifureta Shokugyou de Sekai Saikyou 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fhajime_nagumo-min.jpg?alt=media&token=5a0a391d-7bcc-413b-b908-27cb0d907b2e',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Bojji',
                        anime: 'Ousama Ranking',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fbojji-min.jpg?alt=media&token=b9528711-4fba-4591-9efa-f703badeb2b4',
                        duration: '23 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ENDING: {
                title: CATEGORIES.ENDING,
                nominees: [
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Gedou Sanka',
                        anime: 'Arifureta Shokugyou de Sekai Saikyou 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FGedou%20Sanka-min.jpg?alt=media&token=5dfa9ead-99c8-4987-bb4e-3ac0c9002196',
                        artist: 'FantasticYouth',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Akuma no Ko',
                        anime: 'Shingeki no Kyojin: The Final Season Pt 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FAkuma%20no%20Ko-min.jpeg?alt=media&token=9966f0fb-c7e1-47f8-afed-9cc5c63fc3a8',
                        artist: 'Ai Higuchi',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'salvation',
                        anime: 'Vanitas no Karte Part 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2Fsalvation-min.jpg?alt=media&token=5a5bddde-11d9-4070-93f0-f3f27f0aa4ad',
                        artist: 'Mononkul',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            OPENING: {
                title: CATEGORIES.OPENING,
                nominees: [
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Zankyou Zanka',
                        anime: 'Kimetsu no Yaiba: Yuukaku-hen',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FZankyou%20Zanka-min.jpeg?alt=media&token=000a1e57-c323-4842-b42f-f1478aafea40',
                        artist: 'Aimer',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'The Rumbling',
                        anime: 'Shingeki no Kyojin: The Final Season Pt 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FThe%20Rumbling-min.jpg?alt=media&token=afbf4b45-0cdd-4209-8b96-909605da2618',
                        artist: 'SiM',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Kyoukaisen',
                        anime: '86 Eighty-Six Part 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FKyoukaisen-min.jpeg?alt=media&token=d017f61a-7599-4146-a884-4c56e6cd72ed',
                        artist: 'amazarashi',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ANIME: {
                title: CATEGORIES.ANIME,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: '86 Part 2',
                        titleAlt: '(86 Eighty-Six Part 2)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2F86-min.jpg?alt=media&token=ad5bba42-4126-45e1-9615-e85250f0921e',
                        studio: STUDIOS.A1,
                        chapterCount: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kimetsu no Yaiba: Yuukaku-hen',
                        titleAlt: '(Demons Slayer: Distrito Rojo)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fkimetsu-min.jpg?alt=media&token=07404bf9-be7d-4cb1-9db2-1c9547c8344c',
                        studio: STUDIOS.UFOTABLE,
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Sono Bisque Doll wa Koi wo Suru',
                        titleAlt: '(My Dress-Up Darling)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fsono_bisque-min.jpg?alt=media&token=c445183d-3ce7-4d5a-8c13-3b82d4824ca6',
                        studio: STUDIOS.CLOVERWORKS,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
        },
    },
    {
        title: 'PRIMAVERA 2022',
        key: 'spring',
        categories: {
            SEIYUU_F: {
                title: CATEGORIES.SEIYUU_F,
                nominees: [
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Youmiya, Hina',
                        character: 'Ginny Fin de Salvan',
                        anime: 'Shijou Saikyou no Daimaou, Murabito A ni Tensei suru',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Fyoumiya_hina-min.jpg?alt=media&token=25b0e8ce-38e1-4395-a756-3b06e273f75a',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Hayami, Saori',
                        character: 'Yor Forger',
                        anime: 'Spy x Family',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Fhayami%2C_saori-min.jpg?alt=media&token=9330e85c-8ce8-4b26-b070-d84c00392629',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Koga, Aoi',
                        character: 'Shouko Komi',
                        anime: 'Komi-san wa, Comyushou desu. 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Fkoga_aoi-min.jpg?alt=media&token=3f18e828-aea2-49d7-b069-66a7329ca9cf',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            SEIYUU_M: {
                title: CATEGORIES.SEIYUU_M,
                nominees: [
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Ootsuka, Takeo',
                        character: 'Leon Fou Bartfort',
                        anime: 'Otome Game Sekai wa Mob ni Kibishii Sekai desu',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Footsuka_takeo-min.jpg?alt=media&token=7dbd7508-9c53-4a66-ad70-b55e6130df6d',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Kobayashi, Chiaki',
                        character: 'Yuuichi Katagiri',
                        anime: 'Tomodachi Game',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Fkobayashi_chiaki-min.jpg?alt=media&token=9ba41f48-e8a8-4ce5-ad2d-03869b3cab68',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Eguchi, Takuya',
                        character: 'Loid "Twilight" Forger',
                        anime: 'Spy x Family',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2Feguchi_takuya-min.jpg?alt=media&token=d3867b67-7739-415e-9ef9-2f46671c2dd1',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            FIRST_IMPRESSION: {
                title: CATEGORIES.FIRST_IMPRESSION,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Tomodachi Game',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Ftomodachi-min.jpg?alt=media&token=7844ce04-d41d-427c-be8f-382048f4a315',
                        studio: STUDIOS.OKURUTO,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Spy x Family',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fspy_family-min.jpg?alt=media&token=da285b7a-b16a-4894-b62d-19ed06763502',
                        studio: STUDIOS.WIT_CLOVERWORKS,
                        chapterCount: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Aharen-san wa Hakarenai',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FAharen-san%20wa%20Hakarenai-min.jpg?alt=media&token=391a5654-777e-4281-9249-a615bafb6d77',
                        studio: STUDIOS.FELIX,
                        duration: '',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            SCORE: {
                title: CATEGORIES.SCORE,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Tate no Yuusha no Nariagari Season 2',
                        titleAlt: '(The Rising of the Shield Hero Season 2)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Ftate_no_yuusha-min.jpg?alt=media&token=7a3156c2-a6a8-4e48-ab4d-1bbe302b24ee',
                        studio: STUDIOS.KINEMA_DR,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Tomodachi Game',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Ftomodachi-min.jpg?alt=media&token=7844ce04-d41d-427c-be8f-382048f4a315',
                        studio: STUDIOS.OKURUTO,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kaguya-sama wa Kokurasetai: Ultra Romantic',
                        titleAlt: '(Kaguya-sama: Love is War - Ultra Romantic)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fkaguya-min.jpg?alt=media&token=463568e5-0b3b-44db-ba4c-ecd2da7ac122',
                        studio: STUDIOS.A1,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ANIMATION: {
                title: CATEGORIES.ANIMATION,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Spy x Family',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fspy_family-min.jpg?alt=media&token=da285b7a-b16a-4894-b62d-19ed06763502',
                        studio: STUDIOS.WIT_CLOVERWORKS,
                        chapterCount: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Date A Live IV',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FDate%20A%20Live-min.jpg?alt=media&token=fd053eb9-713f-4aed-8e51-b746cac6c96b',
                        studio: STUDIOS.UFOTABLE,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kaguya-sama wa Kokurasetai: Ultra Romantic',
                        titleAlt: '(Kaguya-sama: Love is War - Ultra Romantic)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fkaguya-min.jpg?alt=media&token=463568e5-0b3b-44db-ba4c-ecd2da7ac122',
                        studio: STUDIOS.A1,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            CLOSE: {
                title: CATEGORIES.CLOSE,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Aharen-san wa Hakarenai',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FAharen-san%20wa%20Hakarenai-min.jpg?alt=media&token=391a5654-777e-4281-9249-a615bafb6d77',
                        studio: STUDIOS.FELIX,
                        duration: '',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kaguya-sama wa Kokurasetai: Ultra Romantic',
                        titleAlt: '(Kaguya-sama: Love is War - Ultra Romantic)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fkaguya-min.jpg?alt=media&token=463568e5-0b3b-44db-ba4c-ecd2da7ac122',
                        studio: STUDIOS.A1,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Date A Live IV',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FDate%20A%20Live-min.jpg?alt=media&token=fd053eb9-713f-4aed-8e51-b746cac6c96b',
                        studio: STUDIOS.UFOTABLE,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            VILLAIN: {
                title: CATEGORIES.VILLAIN,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Ard Meteor',
                        anime: 'Shijou Saikyou no Daimaou, Murabito A ni Tensei suru',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fard_meteor-min.jpg?alt=media&token=a0f54782-704d-47d6-8de3-586c41ddd75b',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Hiro Segawa',
                        anime: 'Kakkou no Iinazuke',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fhiro_segawa-min.jpg?alt=media&token=14c65a18-7a8d-4531-8ad4-22694e9f81a7',
                        duration: '24 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Murdoch Swan',
                        anime: 'Spy x Family',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fmurdock_swan-min.jpg?alt=media&token=d2ca22de-2e5c-482b-b2f9-5dd98bf06078',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            HUSBANDO: {
                title: CATEGORIES.HUSBANDO,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Yuuichi Katagiri',
                        anime: 'Tomodachi Game',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fyuuichi_katagiri%20-min.jpg?alt=media&token=cdbc3d9b-95d0-4e68-8b0d-f25ff9bb732c',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Miyuki Shirogane',
                        anime: 'Kaguya-sama wa Kokurasetai: Ultra Romantic',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fmiyuki_shirogane-min.jpg?alt=media&token=ebf47ba6-370f-4c46-825b-ea7b61af9186',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Loid "Twilight" Forger',
                        anime: 'Spy x Family',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Floid_forger-min.jpg?alt=media&token=4dcf02e2-9444-42bc-80cd-e97c363b21be',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            WAIFU: {
                title: CATEGORIES.WAIFU,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Yor "Thorn Princess" Forger',
                        anime: 'Spy x Family',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fyor_forger-min.jpg?alt=media&token=842b40ba-c0cf-4f41-be1a-bd6d7a2e6aa5',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Kurumi Tokisaki',
                        anime: 'Date A Live IV',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FKurumi%20Tokisaki-min.jpg?alt=media&token=018b687a-00d1-4d07-a66e-ddb487195fc2',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Ai Hayasaka',
                        anime: 'Kaguya-sama wa Kokurasetai: Ultra Romantic',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fai_hayasaka%20-min.jpg?alt=media&token=33eaf7e1-c78b-4a79-b78e-19209790201b',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            PROTAGONIST: {
                title: CATEGORIES.PROTAGONIST,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Anya Forger',
                        anime: 'Spy x Family',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FAnya%20Forger-min.jpg?alt=media&token=e8a850cc-e2f2-4569-8480-5aaf1991eb52',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Yuuichi Katagiri',
                        anime: 'Tomodachi Game',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fyuuichi_katagiri%20-min.jpg?alt=media&token=cdbc3d9b-95d0-4e68-8b0d-f25ff9bb732c',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Leon Fou Bartfort',
                        anime: 'Otome Game Sekai wa Mob ni Kibishii Sekai desu',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FLeon%20Fou%20Bartfort-min.jpg?alt=media&token=dcaad142-1ccd-4b15-a551-5b7681f7cce8',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ENDING: {
                title: CATEGORIES.ENDING,
                nominees: [
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Shikaku Unmei',
                        anime: 'Kakkou no Iinazuke',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FShikaku%20Unmei-min.jpg?alt=media&token=ee963fc5-2265-4c69-a454-b96885dfed3b',
                        artist: 'Sangatsu no Phantasia',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Kigeki',
                        anime: 'Spy x Family',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FKigeki-min.jpg?alt=media&token=82bef7bd-b7e1-4dd0-b209-1f0194526ce1',
                        artist: 'Gen Hoshino',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Yuzurenai',
                        anime: 'Tate no Yuusha no Nariagari Season 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FYuzurenai-min.jpg?alt=media&token=9843d259-7c97-48de-868f-6893f3a433de',
                        artist: 'Chiai Fujikawa',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            OPENING: {
                title: CATEGORIES.OPENING,
                nominees: [
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'GIRI GIRI feat Suu',
                        anime: 'Kaguya-sama wa Kokurasetai: Ultra Romantic',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FGIRI%20GIRI%20feat%20Suu-min.jpg?alt=media&token=96685dbe-e58f-4554-a6cc-2fe808dd0eb0',
                        artist: 'Masayuki Suzuki',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Ciki Ciki Bam Bam',
                        anime: 'Paripi Koumei',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FCiki%20Ciki%20Bam%20Bam-min.jpg?alt=media&token=c17b57e0-c774-4368-b38a-995a8da5c124',
                        artist: 'QUEENDOM',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Double Shuffle',
                        anime: 'Tomodachi Game',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FDouble%20Shuffle-min.jpg?alt=media&token=d8d9f1c4-4429-4148-b80a-25e889ec3ed4',
                        artist: 'Nana Mizuki',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ANIME: {
                title: CATEGORIES.ANIME,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Date A Live IV',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FDate%20A%20Live-min.jpg?alt=media&token=fd053eb9-713f-4aed-8e51-b746cac6c96b',
                        studio: STUDIOS.UFOTABLE,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Spy x Family',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fspy_family-min.jpg?alt=media&token=da285b7a-b16a-4894-b62d-19ed06763502',
                        studio: STUDIOS.WIT_CLOVERWORKS,
                        chapterCount: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kaguya-sama wa Kokurasetai: Ultra Romantic',
                        titleAlt: '(Kaguya-sama: Love is War - Ultra Romantic)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fkaguya-min.jpg?alt=media&token=463568e5-0b3b-44db-ba4c-ecd2da7ac122',
                        studio: STUDIOS.A1,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
        },
    },
    {
        title: 'VERANO 2022',
        key: 'summer',
        categories: {
            SEIYUU_F: {
                title: CATEGORIES.SEIYUU_F,
                nominees: [
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Anzai, Chika',
                        character: 'Chisato Nishikigi',
                        anime: 'Lycoris Recoil',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FAnzai%20Chika-min.jpg?alt=media&token=dc5b64cd-2037-4df5-8ef3-61c4c8ca2a88',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Amamiya, Sora',
                        character: 'Nazuna Nanakusa',
                        anime: 'Yofukashi no Uta',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FAmamiya%20Sora-min.jpg?alt=media&token=250f1dd7-15e8-47ac-8f88-ba070d721c8d',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Hanamori, Yumiri',
                        character: 'Akira Asai',
                        anime: 'Yofukashi no Uta',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FHanamori%20Yumiri-min.jpg?alt=media&token=36548438-74f3-427f-a23e-5c6924f8c297',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            SEIYUU_M: {
                title: CATEGORIES.SEIYUU_M,
                nominees: [
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Satou, Gen',
                        character: 'Kou Yamori',
                        anime: 'Yofukashi no Uta',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FSatou%20Gen-min.jpg?alt=media&token=254cfc89-9905-48a8-9c94-05cdfe1bc124',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Matsuoka, Yoshitsugu',
                        character: 'Majima',
                        anime: 'Lycoris Recoil',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FMatsuoka%20Yoshitsugu-min.jpg?alt=media&token=ad6cab5b-d44e-4afd-8679-e7ebdc4b55f1',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Matsuoka, Yoshitsugu',
                        character: 'Bell Cranel',
                        anime: 'Danmachi IV',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FMatsuoka%20Yoshitsugu-min.jpg?alt=media&token=ad6cab5b-d44e-4afd-8679-e7ebdc4b55f1',
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            FIRST_IMPRESSION: {
                title: CATEGORIES.FIRST_IMPRESSION,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Cyberpunk: Edgerunners',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FCyberpunk-min.jpg?alt=media&token=b229a85e-4b2d-42d5-8657-4626db051232',
                        studio: STUDIOS.TRIGGER,
                        duration: '10 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Lycoris Recoil',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FLycoris-min.jpg?alt=media&token=6f3c60e8-e269-4fee-a3de-151a7bd4a408',
                        studio: STUDIOS.A1,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e 2nd Season',
                        titleAlt: '(Classroom of the Elite II)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fclassroom-min.jpg?alt=media&token=70569fda-9f05-41fc-8eeb-4ddaf36a9860',
                        studio: STUDIOS.LERCHE,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            SCORE: {
                title: CATEGORIES.SCORE,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Yofukashi no Uta',
                        titleAlt: '(Call of the Night)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FYofukashi%20no%20Uta-min.jpg?alt=media&token=2c6cbaad-17ff-475b-b269-c62d4a40c029',
                        studio: STUDIOS.LIDENFILMS,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Cyberpunk: Edgerunners',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FCyberpunk-min.jpg?alt=media&token=b229a85e-4b2d-42d5-8657-4626db051232',
                        studio: STUDIOS.TRIGGER,
                        duration: '10 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Danmachi IV',
                        titleAlt: 'Is It Wrong to Try to Pick Up Girls in a Dungeon? IV',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fdanmachi-min.jpg?alt=media&token=a44806c8-1528-4c90-a2d3-8ec0211fc2de',
                        studio: STUDIOS.JC_STAFF,
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ANIMATION: {
                title: CATEGORIES.ANIMATION,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Lycoris Recoil',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FLycoris-min.jpg?alt=media&token=6f3c60e8-e269-4fee-a3de-151a7bd4a408',
                        studio: STUDIOS.A1,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Yofukashi no Uta',
                        titleAlt: '(Call of the Night)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FYofukashi%20no%20Uta-min.jpg?alt=media&token=2c6cbaad-17ff-475b-b269-c62d4a40c029',
                        studio: STUDIOS.LIDENFILMS,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Cyberpunk: Edgerunners',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FCyberpunk-min.jpg?alt=media&token=b229a85e-4b2d-42d5-8657-4626db051232',
                        studio: STUDIOS.TRIGGER,
                        duration: '10 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            CLOSE: {
                title: CATEGORIES.CLOSE,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Cyberpunk: Edgerunners',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FCyberpunk-min.jpg?alt=media&token=b229a85e-4b2d-42d5-8657-4626db051232',
                        studio: STUDIOS.TRIGGER,
                        duration: '10 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e 2nd Season',
                        titleAlt: '(Classroom of the Elite II)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fclassroom-min.jpg?alt=media&token=70569fda-9f05-41fc-8eeb-4ddaf36a9860',
                        studio: STUDIOS.LERCHE,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Danmachi IV',
                        titleAlt: 'Is It Wrong to Try to Pick Up Girls in a Dungeon? IV',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fdanmachi-min.jpg?alt=media&token=a44806c8-1528-4c90-a2d3-8ec0211fc2de',
                        studio: STUDIOS.JC_STAFF,
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            VILLAIN: {
                title: CATEGORIES.VILLAIN,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Majima',
                        anime: 'Lycoris Recoil',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FMajima-min.jpg?alt=media&token=95d367f4-e10c-4e57-9a9f-2631b04fb772',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Juggernaut',
                        anime: 'Danmachi IV',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fjuggernaut-min.jpg?alt=media&token=586a1d6f-1ba0-4eed-9d2a-a8f8ded5e551',
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Faputa',
                        anime: 'Made in Abyss: Retsujitsu no Ougonkyou',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FFaputa-min.jpg?alt=media&token=0af2a200-86cd-4a6f-8ff8-d88a90673ad4',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            HUSBANDO: {
                title: CATEGORIES.HUSBANDO,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Bell Cranel',
                        anime: 'Danmachi IV',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FBell%20Cranel-min.jpg?alt=media&token=693413f0-d6b3-458a-861f-7a017375ee91',
                        duration: '11 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Kiyotaka Ayanokouji',
                        anime: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fkiyotaka_ayanokouji-min.jpg?alt=media&token=d34f2d7e-008a-47c7-9422-49b141acd7a5',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Alto Goldfilled',
                        anime: 'Kinsou no Vermeil: Gakeppuchi Majutsushi wa Saikyou no Yakusai to Mahou Sekai wo Tsukisusumu',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FAlto%20Goldfilled-min.jpg?alt=media&token=42521bd9-420a-4d96-8746-cb5bf398a4d3',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            WAIFU: {
                title: CATEGORIES.WAIFU,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Chisato Nishikigi',
                        anime: 'Lycoris Recoil',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FChisato%20Nishikigi-min.jpg?alt=media&token=6dc80b7f-bd62-474d-a955-7c10034c8f35',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Kei Karuizawa',
                        anime: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fkei_karuizawa-min.jpg?alt=media&token=deb5487c-0286-46c7-b5c6-4e5460d06129',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Roxanne',
                        anime: 'Isekai Meikyuu de Harem wo',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FRoxanne-min.jpg?alt=media&token=f26348c6-03bf-4f8f-9f56-160d3b5a6830',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            PROTAGONIST: {
                title: CATEGORIES.PROTAGONIST,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Chisato Nishikigi',
                        anime: 'Lycoris Recoil',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FChisato%20Nishikigi-min.jpg?alt=media&token=6dc80b7f-bd62-474d-a955-7c10034c8f35',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Kiyotaka Ayanokouji',
                        anime: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fkiyotaka_ayanokouji-min.jpg?alt=media&token=d34f2d7e-008a-47c7-9422-49b141acd7a5',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Ojisan',
                        anime: 'Isekai Ojisan',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FOjisan-min.jpg?alt=media&token=54561721-09bf-4a21-b81e-ff4787592524',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ENDING: {
                title: CATEGORIES.ENDING,
                nominees: [
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Hito Shibai',
                        anime: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FHito%20Shibai-min.png?alt=media&token=cc587771-d4d2-4e1d-b6bb-24edc9d99716',
                        artist: 'Mai Fuchigami',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Yofukashi no Uta',
                        anime: 'Yofukashi no Uta',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FYofukashi%20no%20Uta-min.jpeg?alt=media&token=5bf56868-39f5-418d-9270-ac364d6fa326',
                        artist: 'Creepy Nuts',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Hana no Tou',
                        anime: 'Lycoris Recoil',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FHana%20no%20Tou-min.jpg?alt=media&token=aeac2864-7b33-41d4-9e7e-d65bee9bfcec',
                        artist: 'Sayuri',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            OPENING: {
                title: CATEGORIES.OPENING,
                nominees: [
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Dance In The Game',
                        anime: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e 2nd Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FDance%20In%20The%20Game-min.jpeg?alt=media&token=a89eba76-4afa-4d5d-b1c9-10915eeec15f',
                        artist: 'ZAQ',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Daten',
                        anime: 'Yofukashi no Uta',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FDaten-min.jpeg?alt=media&token=af066d5a-e762-410c-a56c-268ef92a0d97',
                        artist: 'Creepy Nuts',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'HOLLOW HUNGER',
                        anime: 'Overlord IV',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FHOLLOW%20HUNGER-min.jpeg?alt=media&token=6bff8e6f-bd5b-4382-93b3-7879dfb3c862',
                        artist: 'OxT',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ANIME: {
                title: CATEGORIES.ANIME,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Youkoso Jitsuryoku Shijou Shugi no Kyoushitsu e 2nd Season',
                        titleAlt: '(Classroom of the Elite II)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2Fclassroom-min.jpg?alt=media&token=70569fda-9f05-41fc-8eeb-4ddaf36a9860',
                        studio: STUDIOS.LERCHE,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Cyberpunk: Edgerunners',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FCyberpunk-min.jpg?alt=media&token=b229a85e-4b2d-42d5-8657-4626db051232',
                        studio: STUDIOS.TRIGGER,
                        duration: '10 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Lycoris Recoil',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FLycoris-min.jpg?alt=media&token=6f3c60e8-e269-4fee-a3de-151a7bd4a408',
                        studio: STUDIOS.A1,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
        },
    },
    {
        title: 'OTOÑO 2022',
        key: 'fall',
        categories: {
            SEIYUU_F: {
                title: CATEGORIES.SEIYUU_F,
                nominees: [
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Fairouz, Ai',
                        character: 'Power',
                        anime: 'Chainsaw Man',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FFairouz%20Ai-min.jpg?alt=media&token=37342da6-3706-4e2f-93eb-3f9bff350ff3',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Aoyama, Yoshino',
                        character: 'Hitori "Bocchi" Gotou',
                        anime: 'Bocchi the Rock!',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FAoyama%20Yoshino-min.jpg?alt=media&token=db8f84ca-d93e-41eb-b110-3377c92a68b0',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Oonishi, Saori',
                        character: 'Akari Watanabe',
                        anime: 'Fuufu Ijou, Koibito Miman.',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FOonishi%20Saori-min.jpg?alt=media&token=5f9f7bc9-3f15-4221-816e-83f5e5876d0e',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            SEIYUU_M: {
                title: CATEGORIES.SEIYUU_M,
                nominees: [
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Uchiyama, Kouki',
                        character: 'Tomura Shigaraki',
                        anime: 'Boku no Hero Academia 6th Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FUchiyama%20Kouki-min.jpg?alt=media&token=8d9dffcf-150b-4a83-9d30-f0dcd6d73894',
                        duration: '25 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Yamashita, Seiichirou',
                        character: 'Cid Kagenou',
                        anime: 'Kage no Jitsuryokusha ni Naritakute!',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FYamashita%20Seiichirou-min.jpg?alt=media&token=84df0753-b35c-4b53-8846-abb9269d6441',
                        duration: '20 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.PERSON,
                        name: 'Kaito, Tasuku',
                        character: 'Meguru Bachira',
                        anime: 'Blue Lock',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/persons%2FKaito%20Tasuku-min.jpg?alt=media&token=cda5e692-11b6-406e-b124-2dfa42e9baad',
                        duration: '24 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            FIRST_IMPRESSION: {
                title: CATEGORIES.FIRST_IMPRESSION,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Bocchi the Rock!',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FBocchi%20the%20Rock-min.jpg?alt=media&token=3baa9218-8075-49c1-8149-51931a6fa74e',
                        studio: STUDIOS.CLOVERWORKS,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Akiba Meido Sensou',
                        titleAlt: '(Akiba Maid War)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FAkiba%20Meido%20Sensou-min.jpg?alt=media&token=b2b5e122-25d9-4e4e-810a-f9fcf0a9aab2',
                        studio: STUDIOS.PA_WORKS,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kage no Jitsuryokusha ni Naritakute!',
                        titleAlt: '(The Eminence in Shadow)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FKage%20no%20Jitsuryokusha%20ni%20Naritakute!-min.jpg?alt=media&token=d5252343-40f6-41d6-8f28-965f50bf8d98',
                        studio: STUDIOS.NEXUS,
                        duration: '20 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            SCORE: {
                title: CATEGORIES.SCORE,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Chainsaw Man',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FChainsaw%20Man-min.jpg?alt=media&token=73b8dc40-8c6a-44ff-923f-3fa8fee36406',
                        studio: STUDIOS.MAPPA,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Bocchi the Rock!',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FBocchi%20the%20Rock-min.jpg?alt=media&token=3baa9218-8075-49c1-8149-51931a6fa74e',
                        studio: STUDIOS.CLOVERWORKS,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Kage no Jitsuryokusha ni Naritakute!',
                        titleAlt: '(The Eminence in Shadow)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FKage%20no%20Jitsuryokusha%20ni%20Naritakute!-min.jpg?alt=media&token=d5252343-40f6-41d6-8f28-965f50bf8d98',
                        studio: STUDIOS.NEXUS,
                        duration: '20 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ANIMATION: {
                title: CATEGORIES.ANIMATION,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Mob Psycho 100 III',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FMob%20Psycho%20100%20III-min.jpg?alt=media&token=6ff37783-4ea8-4ea5-b404-32c4ad0e26b6',
                        studio: STUDIOS.BONES,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Bocchi the Rock!',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FBocchi%20the%20Rock-min.jpg?alt=media&token=3baa9218-8075-49c1-8149-51931a6fa74e',
                        studio: STUDIOS.CLOVERWORKS,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Bleach: Sennen Kessen-hen',
                        titleAlt: '(Bleach: Thousand-Year Blood War)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FBleach-min.jpg?alt=media&token=ca4881cc-a37f-434d-aa6b-1d221159ec8c',
                        studio: STUDIOS.PIERROT,
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            CLOSE: {
                title: CATEGORIES.CLOSE,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Chainsaw Man',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FChainsaw%20Man-min.jpg?alt=media&token=73b8dc40-8c6a-44ff-923f-3fa8fee36406',
                        studio: STUDIOS.MAPPA,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Bocchi the Rock!',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FBocchi%20the%20Rock-min.jpg?alt=media&token=3baa9218-8075-49c1-8149-51931a6fa74e',
                        studio: STUDIOS.CLOVERWORKS,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Mob Psycho 100 III',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FMob%20Psycho%20100%20III-min.jpg?alt=media&token=6ff37783-4ea8-4ea5-b404-32c4ad0e26b6',
                        studio: STUDIOS.BONES,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            VILLAIN: {
                title: CATEGORIES.VILLAIN,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Nagi "Uzuko"',
                        anime: 'Akiba Meido Sensou',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FNagi-min.jpg?alt=media&token=0e3d9e60-16c7-43dd-9607-8fa315e4dfd7',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Tomura Shigaraki',
                        anime: 'Boku no Hero Academia 6th Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FTomura%20Shigaraki-min.jpg?alt=media&token=8f196388-34d8-4009-a004-56d584c7be57',
                        duration: '25 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Dabi',
                        anime: 'Boku no Hero Academia 6th Season',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FDabi-min.jpg?alt=media&token=9d589aee-f782-42f6-a493-4bfa62c4ec03',
                        duration: '25 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            HUSBANDO: {
                title: CATEGORIES.HUSBANDO,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Loid "Twilight" Forger',
                        anime: 'Spy x Family Part 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Floid_forger-min.jpg?alt=media&token=4dcf02e2-9444-42bc-80cd-e97c363b21be',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Meguru Bachira',
                        anime: 'Blue Lock',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FMeguru%20Bachira-min.jpg?alt=media&token=1284b619-6d01-4d42-b336-ef3e3d895d87',
                        duration: '24 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Kishibe',
                        anime: 'Chainsaw Man',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FKishibe-min.jpg?alt=media&token=6552b1ff-8d26-42ac-85eb-ce21628a4c32',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            WAIFU: {
                title: CATEGORIES.WAIFU,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Ranko Mannen',
                        anime: 'Akiba Meido Sensou',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FRanko%20Mannen-min.jpg?alt=media&token=a5ca28c9-eada-4dcc-bf10-538ecf5fbece',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Hitori "Bocchi" Gotou',
                        anime: 'Bocchi the Rock!',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FHitori%20Bocchi%20Gotou-min.jpg?alt=media&token=70742deb-3a82-46dc-965f-312869dd2b66',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Yor "Thorn Princess" Forger',
                        anime: 'Spy x Family Part 2',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2Fyor_forger-min.jpg?alt=media&token=842b40ba-c0cf-4f41-be1a-bd6d7a2e6aa5',
                        duration: '13 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            PROTAGONIST: {
                title: CATEGORIES.PROTAGONIST,
                nominees: [
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Shigeo "Mob" Kageyama',
                        anime: 'Mob Psycho 100 III',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FShigeo%20Mob%20Kageyama-min.jpg?alt=media&token=c608ddd6-f858-49ca-b48d-d1fc15a133e1',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Hitori "Bocchi" Gotou',
                        anime: 'Bocchi the Rock!',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FHitori%20Bocchi%20Gotou-min.jpg?alt=media&token=70742deb-3a82-46dc-965f-312869dd2b66',
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.CHARACTER,
                        character: 'Cid Kagenou',
                        anime: 'Kage no Jitsuryokusha ni Naritakute!',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/characters%2FCid%20Kagenou-min.jpg?alt=media&token=a70d7f31-ad75-4dff-bcf8-cf539dd8de8c',
                        duration: '20 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ENDING: {
                title: CATEGORIES.ENDING,
                nominees: [
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Fight Song',
                        anime: 'Chainsaw Man',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FFight%20Song-min.jpeg?alt=media&token=7e1d4509-e4f3-4283-89f1-41f074a7cf83',
                        artist: 'Eve',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'CHAINSAW BLOOD',
                        anime: 'Chainsaw Man',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FCHAINSAW%20BLOOD-min.jpeg?alt=media&token=62e70146-13c1-44ee-aee9-43ea649ef2eb',
                        artist: 'Vaundy',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Maid Daikaiten',
                        anime: 'Akiba Meido Sensou',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FMaid%20Daikaiten-min.png?alt=media&token=ccec0ec3-5651-46eb-b689-89da6f336359',
                        artist: 'the Ton Tokoton staff',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            OPENING: {
                title: CATEGORIES.OPENING,
                nominees: [
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'KICK BACK',
                        anime: 'Chainsaw Man',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FKICK%20BACK-min.jpeg?alt=media&token=fffd12eb-7f2c-400c-87e7-502e0bf6d4f4',
                        artist: 'Kenshi Yonezu',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: 'Seishun Complex',
                        anime: 'Bocchi the Rock!',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2FSeishun%20Complex-min.jpg?alt=media&token=95c2eeb1-d2f7-45ef-9518-6385bf372542',
                        artist: 'Kessoku Band',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.SONG,
                        title: '1',
                        anime: 'Mob Psycho 100 III',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/songs%2Fone-min.jpeg?alt=media&token=5eda80ef-4517-48de-a97c-9aa354d49f29',
                        artist: 'MOB CHOIR',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
            ANIME: {
                title: CATEGORIES.ANIME,
                nominees: [
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Renai Flops',
                        titleAlt: '(Love Flops)',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FRenai%20Flops-min.jpg?alt=media&token=32616b2b-ef08-4ae1-a982-a29d6ac191d9',
                        studio: STUDIOS.PASSIONE,
                        duration: '',
                        previewURL: '',
                        isWinner: false,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Bocchi the Rock!',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FBocchi%20the%20Rock-min.jpg?alt=media&token=3baa9218-8075-49c1-8149-51931a6fa74e',
                        studio: STUDIOS.CLOVERWORKS,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: true,
                    },
                    {
                        type: NOMINEE_TYPE.ANIME,
                        title: 'Chainsaw Man',
                        titleAlt: '',
                        img: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/posters%2FChainsaw%20Man-min.jpg?alt=media&token=73b8dc40-8c6a-44ff-923f-3fa8fee36406',
                        studio: STUDIOS.MAPPA,
                        duration: '12 capítulos',
                        previewURL: '',
                        isWinner: false,
                    },
                ],
            },
        },
    },
];

export default MAIN_DATA;
