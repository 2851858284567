import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width: 100%;
    padding: 15px;
    border-radius: 38px;
    overflow: hidden;
    position: relative;
`;

const Video = styled.video`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    max-height: 100%;
    width: 100%;
    object-fit: cover;
    filter: blur(4px);
`;

const SEASONS_VIDEOS = {
    winter: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/videos%2Fwinter.mp4?alt=media&token=c6df288f-bfcc-42d5-8c73-f02cac0f91c2',
    fall: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/videos%2Ffall.mp4?alt=media&token=361ffc96-ec89-4614-a5cf-ba2134e9f1e7',
    summer: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/videos%2Fsummer.mp4?alt=media&token=cccf8947-bda8-4496-bae5-1b1285a96bd0',
    spring: 'https://firebasestorage.googleapis.com/v0/b/profeawards.appspot.com/o/videos%2Fspring.mp4?alt=media&token=aa17429d-27bb-4371-9fbb-31ecb5375c61',
};

const AwardsContainer = ({ season, children }) => {
    return (
        <Container>
            <Video src={SEASONS_VIDEOS[season]} muted={true} autoPlay={true} loop={true} />
            {children}
        </Container>
    );
};

export default AwardsContainer;
