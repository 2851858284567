import React from 'react';
import { GOLDEN } from 'resources/constants/colors';
import styled from 'styled-components';
import RibbonSVG from 'resources/img/ribbon.svg';
import { NOMINEE_TYPE } from 'data';

const Container = styled.div`
    border-radius: 15px;
    overflow: hidden;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 768px) {
        width: 100%;
        flex-direction: row;
        ${({isWinner}) => isWinner ? 'order: -1;' : ''}
    }
`;

const Background = styled.div`
    position: absolute;
    background-color: black;
    opacity: 0.35;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
`;

const Border = styled.div`
    z-index: 1;
    width: 170px;
    border: solid 4px white;
    margin-top: 20px;
    margin-bottom: 10px;
    img {
        width: 100%;
        height: auto;
        object-fit: contain;
    }

    @media screen and (max-width: 768px) {
        margin-left: 15px;
        width: 90px;
        border-width: 2px;
        margin-top: 15px;
    }
`;

const BorderGold = styled.div`
    z-index: 1;
    width: 210px;
    border: solid 4px ${GOLDEN};
    margin-top: 20px;
    margin-bottom: 10px;
    padding: 5px;
    position: relative;
    > img {
        position: absolute;
        top: -4px;
        right: -4px;
        width: 50px;
    }
    div {
        border: solid 4px ${GOLDEN};
        img {
            width: 100%;
            height: auto;
            object-fit: contain;
        }
    }
    @media screen and (max-width: 768px) {
        margin-left: 15px;
        width: 90px;
        padding: 3px;
        border-width: 2px;
        margin-top: 15px;
        > img {
            width: 30px;
            top: -2px;
            right: -2px;
        }
        div {
            border-width: 2px;
        }
    }
`;

const TextContainer = styled.div`
    display: flex;
    width: 270px;
    max-width: 270px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    z-index: 1;
    @media screen and (max-width: 768px) {
        max-width: 100%;
        flex: 1;
        width: unset;
        justify-content: flex-start;
        align-items: flex-start;
    }
`;

const TitleContainer = styled.div`
    margin-top: 10px;
    display: flex;
    min-height: 42px;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    flex-direction: column;

    h3 {
        margin: 0;
        font-weight: 700;
        color: white;
        width: 100%;
        max-width: 100%;
        text-align: center;
        font-size: 16px;
    }

    p {
        margin: 0;
        font-weight: 500;
        color: white;
        width: 100%;
        max-width: 100%;
        text-align: center;
        font-size: 14px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        min-height: unset;

        h3 {
            text-align: start;
        }
        p {
            text-align: start;
        }
    }
`;

const TitleAltContainer = styled.div`
    margin-top: 10px;
    display: flex;
    min-height: 32px;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 15px;

    p {
        margin: 0;
        font-weight: 400;
        color: white;
        width: 100%;
        max-width: 100%;
        text-align: center;
        font-size: 12px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        min-height: unset;

        p {
            text-align: start;
        }
    }
`;

const InfoContainer = styled.div`
    margin-top: 5px;
    display: flex;
    max-width: 100%;
    align-items: center;
    justify-content: center;
    padding: 0 15px;
    margin-bottom: 15px;
    p {
        margin: 0;
        font-weight: 600;
        color: white;
        width: 100%;
        max-width: 100%;
        text-align: center;
        font-size: 12px;
    }

    @media screen and (max-width: 768px) {
        width: 100%;
        justify-content: flex-start;
        min-height: unset;
        
        p {
            text-align: start;
        }
    }
`;

const Image = ({ img, title, isWinner }) => {
    return isWinner ? (
        <BorderGold>
            <div>
                <img src={img} alt={title} />
            </div>
            <img src={RibbonSVG} alt={'primer lugar'} />
        </BorderGold>
    ) : (
        <Border>
            <img src={img} alt={title} />
        </Border>
    );
};

const AnimeCard = ({
    type,
    name,
    character,
    anime,
    title,
    titleAlt,
    img,
    studio,
    duration,
    artist,
    previewURL,
    isWinner,
}) => {
    return (
        <Container isWinner={isWinner}>
            <Background />
            <Image isWinner={isWinner} title={title} img={img} />
            <TextContainer>
                <TitleContainer>
                    {type === NOMINEE_TYPE.ANIME && <h3>{title}</h3>}
                    {type === NOMINEE_TYPE.PERSON && <h3>{name}</h3>}
                    {type === NOMINEE_TYPE.PERSON && <p>{character}</p>}
                    {type === NOMINEE_TYPE.CHARACTER && <h3>{character}</h3>}
                    {type === NOMINEE_TYPE.SONG && <h3>{title}</h3>}
                </TitleContainer>
                <TitleAltContainer>
                    {type === NOMINEE_TYPE.ANIME && <p>{titleAlt}</p>}
                    {type === NOMINEE_TYPE.PERSON && <p>{anime}</p>}
                    {type === NOMINEE_TYPE.CHARACTER && <p>{anime}</p>}
                    {type === NOMINEE_TYPE.SONG && <p>{anime}</p>}
                </TitleAltContainer>
                <InfoContainer>
                    {type === NOMINEE_TYPE.ANIME && (
                        <p>{`${studio} | ${duration}`}</p>
                    )}
                    {type === NOMINEE_TYPE.PERSON && <p>{duration}</p>}
                    {type === NOMINEE_TYPE.CHARACTER && <p>{duration}</p>}
                    {type === NOMINEE_TYPE.SONG && <p>{artist}</p>}
                </InfoContainer>
            </TextContainer>
        </Container>
    );
};

export default AnimeCard;
