import AwardsContainer from 'container/AwardsContainer';
import { CATEGORIES_SELECT } from 'data';
import React, { useState } from 'react';
import styled from 'styled-components';
import AnimeCard from './AnimeCard';
import CategoriesSelect from './CategoriesSelect';

const Title = styled.div`
    display: flex;
    width: fit-content;
    justify-self: center;
    padding: 10px 25px;
    margin: 0 auto;
    border-radius: 15px 15px 0 0;
    background-color: #ddb4a8;
    span {
        color: white;
        margin: 0;
        font-size: 25px;
        font-weight: 700;
    }
    @media screen and (max-width: 768px) {
        border-radius: 10px 10px 0 0;
        padding: 10px 35px;
        span {
            font-size: 17px;
        }
    }
`;

const AnimeRow = styled.div`
    display: flex;
    gap: 25px;
    align-items: flex-end;
    justify-content: center;
    @media screen and (max-width: 768px) {
        flex-direction: column;
        align-items: flex-start;
        margin: 25px 0;
    }
`;

const Season = ({ data }) => {
    const [selectedCategory, setSelectedCategory] = useState(
        CATEGORIES_SELECT[0]
    );
    return (
        <section>
            <Title>
                <span>{data.title}</span>
            </Title>
            <AwardsContainer season={data.key}>
                <CategoriesSelect
                    selectedCategory={selectedCategory}
                    onChange={(category) => setSelectedCategory(category)}
                />
                <AnimeRow>
                    {selectedCategory &&
                        data.categories[selectedCategory.value]?.nominees &&
                        data.categories[selectedCategory.value].nominees.map(
                            (anime) => <AnimeCard {...anime} />
                        )}
                </AnimeRow>
            </AwardsContainer>
        </section>
    );
};

export default Season;
